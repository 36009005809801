import React, {useEffect, useState} from "react";
import { NavBar } from "./NavBar";
import { Card } from "./Card";

export const Weather = () => {
    const [tiempo, setTiempo] = useState([]);
    const [prediccion, setPrediccion] = useState([])

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setTiempo(position);

            fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${position.coords.latitude}&lon=${position.coords.longitude}&appid=42a1eaf5f29e185385fd93caaff54917&lang=es
            `, {
                "method": "GET",
                "headers": {}
            })
                .then(response => response.json())
                .then(data => {
                    const tiempoData = data;
                    console.log(data)
                    setTiempo(tiempoData);
                })

            fetch(`https://api.openweathermap.org/data/2.5/forecast?lat=${position.coords.latitude}&lon=${position.coords.longitude}&appid=42a1eaf5f29e185385fd93caaff54917&lang=es`, 
            {
                "method": "GET",
                "headers": {}
            })
                .then(response => response.json())
                .then(data => {
                    const prediccionData = data;
                    console.log(data)
                    setPrediccion(prediccionData);
                })
        });
    }, [])

    let urlWeather = 'https://api.openweathermap.org/data/2.5/weather?appid=42a1eaf5f29e185385fd93caaff54917&lang=es';
    let urlCity = "&q=";

    let urlForecast = 'https://api.openweathermap.org/data/2.5/forecast?appid=42a1eaf5f29e185385fd93caaff54917&lang=es';

    const [weather, setWeather] = useState([]);
    const [forecast, setForecast] = useState([]);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [location, setLocation] = useState("");
    const [dias, setDias] = useState("");


    const getLocation = async(loc) => {
        setLoading(true);
        setLocation(loc);

        //Tiempo actual

        urlWeather = urlWeather + urlCity + loc;
        
        await fetch(urlWeather).then((response) => {
            if (!response.ok) throw {response}
            return response.json();
        }).then((weatherData) => {
            setWeather(weatherData);
            console.log(weatherData)
        }).catch(error => {
            setLoading(false);
            setShow(false);
        });


        // Pronostico 5 dias

        urlForecast = urlForecast + urlCity + loc;

        await fetch(urlForecast).then((response) => {
            if (!response.ok) throw {response}
            return response.json();
        }).then((forecastData) => {
            setForecast(forecastData);
            setLoading(false);
            setShow(true);
        }).catch(error => {
            setLoading(false);
            setShow(false);
        });
        
    }

    return (
        <React.Fragment>
            <NavBar
                newLocation = {getLocation}
            />

            <Card
                showData = {show}
                loadingData = {loading}
                weather = {weather}
                forecast = {forecast}
                dias = {dias}
                tiempo = {tiempo}
                prediccion = {prediccion}
            />
        </React.Fragment>
    )
}