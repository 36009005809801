import './App.css';
import { Weather } from './components/Weather';
import { Footer } from './components/Footer';

function App() {

  return (
    <div className="App">
      <Weather />
      <Footer />
    </div>
  );
}

export default App;
